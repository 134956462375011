<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
import Utils from "@/helpers/Utils";
import Role from "@/data/Roles";
import Dropdown from '@/components/Dropdown';

let roleChecker = new Role();

let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  data() {
    return {
      title: "Reports",
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Report",
          active: true,
        },
      ],
      recentData: [],
      selectedFile: null,
      userDateTasks: null,
      role: preference.getData(Preference.KEY_ROLE_ID),
      isLoading: false,
      isImageLoading: false,
      recentDataScreen: [],
      recentDataLenth: 40,
      isLoadingScreenShotsthumb: false,
      isLoadingMembers: false,
      projectListSelectOption: [],
      projectIdList: [],
      memberListSelectOption: [],
      selectedMemberId: null,
      fromDate: null,
      toDate: null,
      datePickerPlaceholder: "Today",
      activitiesData: [],
      selectedMemberNameInit: preference.getData(Preference.KEY_NAME),
      all: {
        id: null,
        name: "All",
      },
      allByDates: {
        id: null,
        name: "All by Dates",
      },
      allByMember: {
        id: "all-by-members",
        name: "All by Members",
      },
      me: {
        id: null,
        name: "Me",
      },
      selectedProjectId: null,
      isOwnerOfTheSelectedProject: false,
      reportTableData: [],
      reportTableFields: [
        { key: "date", sortable: false, label: "Date" },
        { key: "total_time", sortable: true, label: "Total Time" },
        { key: "idle_time", sortable: false, label: "Thinking Time" },
        { key: "active_time", sortable: false, label: "Executing Time" },
        { key: "percentage", sortable: false, label: "Percentage" },
      ],
    };
  },

  components: {
    Layout,
    PageHeader,
    Dropdown
  },

  methods: {



    userDateTasksfunction(item) {
      // Find the matching task from this.userDateTasks
      const matchingTask = this.userDateTasks.find(task =>
        task.name === item.user_name && task.adjusted_created_at === item.date
      );
      console.log(matchingTask);

      // Return the jira_tissue_key if a matching task was found, otherwise return null
      return matchingTask && matchingTask.jira_issue_keys != '0' ? matchingTask.jira_issue_keys : null;
    }

    ,
    redirectToAvtivitie(user) {
      const url = `/activities?id=${user.user_id}&name=${user.user_name}&date=${user.date}`;
      window.open(url, '_blank');
    }
    ,
    invokeModal(modalId, index) {
      console.log('invkeModal clicked');
      this.selectedFile = this.recentDataScreen[index].file;
      let userName = this.recentDataScreen[index].user;
      let userAssignedProjectName = this.recentDataScreen[index].project;
      this.userNameAndAssignedProjectName = userName + " | " + userAssignedProjectName;

      if (this.selectedFile != null) {
        this.$bvModal.show(modalId);
      } else {
        this.$bvToast.toast(
          "Either removed or you don't have enough permission to see this!",
          {
            title: "No screenshot!",
            variant: "warning",
            solid: true,
          }
        );
      }
    },

    async getRecentData(item) {
      let _this = this;
      _this.recentDataScreen = []

      let fromDate = Utils.formatDate(item.date);
      let toDate = Utils.formatDate(item.date);
      let timezone = Utils.getMyTimezone();

      await httpRequest
        .getDashboardActivities(
          fromDate,
          toDate,
          timezone,
          null,
          item.user_id
        ).then(function (response) {
          if (response.success) {
            _this.recentDataLenth = response.data.date_data[0].day_data.length * 2;
            _this.getImage(response);
          }
        });
    },

    async getImage(responses) {
      try {
        let imgCount = 0

        let k = 0
        const dataLength = responses.data.date_data[0].day_data.length;
        this.recentData = []; // Initialize with an empty array

        for (let i = 0; i < dataLength; i++) {
          const thumbNames = responses.data.date_data[0].day_data[i].thumb_names;

          if (thumbNames) {
            k = 0;
            const thumbNameArray = thumbNames.split(',');
            for (let thumbName of thumbNameArray) {
              const screenshotUrl = responses.data.date_data[0].day_data[i].screenshots[0].screenshot_url.split(',')[k++]; // Assuming first screenshot

              // Generate image URLs
              const [fileResponse, thumbResponse] = await Promise.all([
                httpRequest.generateImageUrl(screenshotUrl),
                httpRequest.generateImageUrl(thumbName),
              ]);

              const imageData = {
                file: fileResponse.success ? fileResponse.data.URL : null, // Set default if unsuccessful
                thumb: thumbResponse.success ? thumbResponse.data.URL : null,
              };

              // Add the loaded image data to recentData
              this.recentDataScreen.push(imageData);

              // Append the new image HTML to the DOM

              this.appendImageToDOM(imageData, imgCount++);

            }
          }
        }
        this.isImageLoading = false;



      } catch (error) {
        // Handle errors appropriately, e.g., display an error message
      }
    },

    appendImageToDOM(imageData, index) {
      const thumbnailImagesDiv = document.querySelector('.thumbnailimages');
      console.log("thumbnail  ", thumbnailImagesDiv);

      const htmlString = `
    <div class="col-2 mb-4 card-image-div">
      <div style="cursor: pointer; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;"
         class="card hovers invokeModal-${index}">
        <img class="card-img" src="${imageData.thumb}" height="90px" width="100%">
      </div>
    </div>
  `;



      // Append the HTML string to the parent element
      thumbnailImagesDiv.insertAdjacentHTML('beforeend', htmlString);

      let modal = document.querySelector(`.invokeModal-${index}`);
      if (modal) {
        modal.onclick = () => {
          this.invokeModal('img-view', index);
        };
      }
    }
    ,

    showScreenShotGallary(item) {
      this.isImageLoading = true;

      let rowData = item;
      this.getRecentData(item);

      console.log(rowData);
    },

    rowClass(item) {
      return item.total_time < '08:00:00' ? 'text-red' : ''
    },

    validateSelection(selection) {
      if (selection) {
        this.selectedMemberId = selection.id;

      }
    },
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id.toString(), resource, action);
    },
    getProjectList() {
      let accessPermission = this.isRoleAccess(this.role, "Project", "read");

      let _this = this;
      _this.isLoading = true;
      return httpRequest.getProjectList(accessPermission).then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          response.data.forEach(function (projectData) {
            if (projectData.project.project_status == 1) {
              let project = {
                id: projectData.project.project_id,
                name: projectData.project.project_name,
              };
              _this.projectListSelectOption.push(project);
              if (_this.isRoleAccess(projectData.role_id, "Project", "read")) {
                _this.projectIdList.push(projectData.project.project_id);
              }
            }
          });
        }
      });
    },
    getProjectMemberList() {
      let _this = this;
      _this.memberListSelectOption = [];
      if (_this.selectedProjectId != null) {
        _this.isLoadingMembers = true;
        httpRequest.getProjectMembers(_this.selectedProjectId).then(function (response) {
          _this.isLoadingMembers = false;
          if (response.success) {
            let myUserId = preference.getData(Preference.KEY_USER_ID);
            _this.isOwnerOfTheSelectedProject = false;
            response.data.forEach(function (member) {
              let myRoleId = -1;
              if (member.user !== null) {
                if (member.user.user_id === myUserId) {
                  _this.selectedMemberId = member.user.user_id;
                  myRoleId = member.role_id;
                  _this.isOwnerOfTheSelectedProject = _this.isRoleAccess(
                    myRoleId,
                    "Member",
                    "read"
                  );
                }
                let user = {
                  id: member.user.user_id,
                  name: member.user.name,
                };
                _this.memberListSelectOption.push(user);
              }
            });
            _this.memberListSelectOption.push(_this.allByDates);
            _this.memberListSelectOption.push(_this.allByMember);

            if (
              _this.isOwnerOfTheSelectedProject == false &&
              _this.isRoleAccess(_this.role, "Member", "read") == false
            ) {
              _this.memberListSelectOption = [];
              let user = {
                id: _this.selectedMemberId,
                name: preference.getData(Preference.KEY_NAME),
              };
              _this.memberListSelectOption.push(user);
            }
          }
        });
      } else {
        _this.getAllMembersBasedOnProjectList();
      }
    },
    getAllMembersBasedOnProjectList() {
      let _this = this;
      _this.memberListSelectOption = [];
      if (_this.projectIdList.length > 0) {
        _this.isLoadingMembers = true;
        httpRequest.getProjectMembers(_this.projectIdList).then(function (response) {
          _this.isLoadingMembers = false;
          if (response.success) {
            _this.isOwnerOfTheSelectedProject = false;
            let all = {
              id: 'all_member_secret_ID',
              name: 'All Members',
            };
            _this.memberListSelectOption.push(all);
            response.data.forEach(function (member) {
              if (member.user !== null) {
                let user = {
                  id: member.user.user_id,
                  name: member.user.name,
                };
                _this.memberListSelectOption.push(user);
              }
            });
            // remove duplicate if exist
            if (_this.memberListSelectOption.length > 0) {
              const key = "id";
              _this.memberListSelectOption = [
                ...new Map(
                  _this.memberListSelectOption.map((item) => [item[key], item])
                ).values(),
              ];
            } else {
              _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
              _this.me.id = _this.selectedMemberId;
              _this.me.name = preference.getData(Preference.KEY_NAME);
              _this.memberListSelectOption.push(this.me);
            }
          }
        });
      } else {
        _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
        _this.me.id = _this.selectedMemberId;
        _this.me.name = preference.getData(Preference.KEY_NAME);
        _this.memberListSelectOption.push(this.me);
      }
    },
    loadSelectedData() {
      let _this = this;
      let fromDate = Utils.formatDate(this.fromDate);
      let toDate = Utils.formatDate(this.toDate);
      _this.isLoading = true;
      let timezone = Utils.getMyTimezone();
      if (_this.selectedMemberId == "all-by-members") {
        //This one for All member of a project
        _this.reportTableFields = [
          { key: "user_name", sortable: false, label: "Name" },
          { key: "email", sortable: false, label: "Email" },
          { key: "total_time", sortable: true, label: "Total Time" },
          { key: "idle_time", sortable: false, label: "Thinking Time" },
          { key: "active_time", sortable: false, label: "Executing Time" },
          { key: "percentage", sortable: false, label: "Percentage" },
          { key: "action", sortable: false, label: "Action" },
        ];
      } else if (_this.selectedMemberId == "all_member_secret_ID") {
        _this.reportTableFields = [
          { key: "date", sortable: false, label: "Date" },
          { key: "user_name", sortable: false, label: "Name" },
          { key: "total_time", sortable: true, label: "Total Time" },
          { key: "idle_time", sortable: false, label: "Thinking Time" },
          // { key: "meeting_time", sortable: false, label: "Meeting Time" },
          { key: "active_time", sortable: false, label: "Executing Time" },
          { key: "percentage", sortable: false, label: "Percentage" },
          { key: "action", sortable: false, label: "Action" },

        ];
      }
      else {
        //This one for single member of any project
        _this.reportTableFields = [
          { key: "date", sortable: false, label: "Date" },
          { key: "total_time", sortable: true, label: "Total Time" },
          { key: "idle_time", sortable: false, label: "Thinking Time" },
          { key: "active_time", sortable: false, label: "Executing Time" },
          { key: "percentage", sortable: false, label: "Percentage" },
          { key: "action", sortable: false, label: "Action" },
        ];
      }

      httpRequest
        .getReportPreparationData(
          fromDate,
          toDate,
          _this.selectedProjectId,
          _this.selectedMemberId,
          timezone
        )
        .then(function (response) {
          _this.isLoading = false;
          _this.reportTableData = [];
          if (response.success) {
            _this.userDateTasks = response.data.userDateTasks
            console.log('Response keys:', _this.userDateTasks);
            if (response.data.summeryResult.length > 0) {

              // Remove the class 'some-class'
              if (response.data.summeryResult.length > 10) {
                let cardBody = document.querySelector('.card-body');
                cardBody.classList.remove('card-body-height');
              }


              let reportData;
              let total_sum_time = 0;
              let total_idle_time = 0;
              let active_time = 0;
              response.data.summeryResult.forEach(function (data) {
                total_sum_time = total_sum_time + parseInt(data.total_time);
                total_idle_time = total_idle_time + parseInt(data.idle_time);
                active_time = active_time + parseInt(data.active_time);
                reportData = {
                  date: data.date,
                  user_name: data.name,
                  email: data.email,
                  total_time: Utils.formatTime(data.total_time),
                  idle_time: Utils.formatTime(data.idle_time),
                  active_time: Utils.formatTime(data.total_time - data.idle_time),
                  percentage:
                    Math.round(
                      ((data.total_time - data.idle_time) * 100) / data.total_time
                    ) + "%",
                  user_id: data.user_id,
                };
                _this.reportTableData.push(reportData);
              });
              reportData = {
                date: "Total  ",
                email: _this.selectedMemberId == "all-by-members" ? "Total " : "",
                total_time: Utils.formatTime(total_sum_time.toString()),
                idle_time: Utils.formatTime(total_idle_time.toString()),
                active_time: Utils.formatTime(total_sum_time - total_idle_time),
                percentage:
                  Math.round(
                    ((total_sum_time - total_idle_time) * 100) / total_sum_time
                  ) + "%",
              };
              // _this.reportTableData.push(reportData);
            }
          }
        });
    },
    loadInitialData() {
      this.fromDate = Utils.getToday().replaceAll("/", "-");
      this.toDate = Utils.getToday().replaceAll("/", "-");
      this.projectListSelectOption.push(this.all);
      if (this.selectedMemberId == null) {
        this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
        this.me.id = this.selectedMemberId;
        this.me.name = preference.getData(Preference.KEY_NAME);
      }
      this.memberListSelectOption.push(this.me);
      this.loadSelectedData();
    },
    exportSelectedData() {
      let _this = this;
      let fileName = "";
      let user_name = "";
      this.memberListSelectOption.forEach(function (item) {
        if (item.id === _this.selectedMemberId) {
          let today = new Date();
          let time =
            today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
          fileName =
            item.name + "_" + _this.fromDate + "_to_" + _this.toDate + "_" + time;
          user_name = item.name;
        }
      });
      if (this.reportTableData.length > 0) {
        Utils.convertToCsv(this.reportTableData, fileName, user_name);
      }
    },
    expandUrls(data) {
      data.expand = true;
    },
    getHostnameFromUrl(string) {
      if (string) {
        try {
          return new URL(string).hostname;
        } catch (error) {
          return string;
        }
      }
      return null;
    },
    printData() {
      if (this.reportTableData.length > 0) {
        window.print();
      }
    },
  },
  async created() {
    await this.loadInitialData();
    await this.getProjectList();
    await this.getAllMembersBasedOnProjectList();
  },
  watch: {
    // isImageLoading() {
    //   this.isImageLoading = false;
    // },

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-body card-body-height">
            <div class="row report-form-row">
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">From</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <!-- Icon or content to prepend -->
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker :date-format-options="{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    }" id="from-datepicker" v-model="fromDate" placeholder="Today"></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">To</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker :date-format-options="{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    }" id="to-datepicker" v-model="toDate" placeholder="Today"></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-2">
                <label class="report-form-label">Project</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-folder-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select :options="projectListSelectOption" v-model="selectedProjectId" value-field="id"
                        text-field="name" v-on:change="getProjectMemberList"></b-form-select>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-2">
                <label class="report-form-label">Team Members</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-user-3-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <!-- <b-form-select :options="memberListSelectOption" v-model="selectedMemberId" value-field="id"
                        text-field="name" :disabled="isLoadingMembers"></b-form-select> -->
                      <Dropdown class="dropdown-width" :options="memberListSelectOption" :maxItem="200"
                        v-on:selected="validateSelection" :initialData="selectedMemberNameInit">

                      </Dropdown>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-md-4 col-xl-2 report-search">
                <label class="report-form-label">Action</label>
                <b-button block variant="primary" class="customs-bg-color" v-on:click="loadSelectedData"><i
                    class="ri-eye-line" style="vertical-align: bottom"></i> VIEW
                </b-button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <b-table ref="projectTable" show-empty :items="reportTableData" :tbody-tr-class="rowClass"
                  :striped="false" responsive="" :fields="reportTableFields" :bordered="true" :hover="true"
                  :dark="false" :busy="isLoading" id="reportTable" caption-top>

                  <template #table-caption v-if="reportTableData.length > 0">
                    <div class="alert alert-info text-center" style="
                        background: #fff;
                        border: none;
                        margin-top: -10px;
                        position: relative;
                        padding: 0;
                      " role="alert" v-if="!isLoading">
                      <div class="alert alert-info- mt-3- pb-1 d-flex justify-content-between align-items-center"
                        style="border: 1px solid #ced4da; padding: 5px 18px">
                        <span class="" :style="{
      color: 'black',
      fontSize: '15px',
      fontWeight: '700',
      marginLeft: '0px',
    }">
                          {{
      memberListSelectOption.find(
        (member) => member.id === selectedMemberId
      )?.name
    }}
                          -
                          {{
        projectListSelectOption.find(
          (project) => project.id === selectedProjectId
        )?.name
      }}
                          - {{ fromDate }} to {{ toDate }}
                        </span>
                        <div class="actions">
                          <i class="ri-file-download-line text-primary- cursor-pointer" style="
                              vertical-align: bottom;
                              font-size: 22px;
                              margin-right: 10px;
                              cursor: pointer;
                              color: #ff7c47;
                            " v-on:click="exportSelectedData" v-b-tooltip.hover.top title="Download CSV">
                          </i>
                          <i class="ri-printer-line text-primary- cursor-pointer" style="
                              vertical-align: bottom;
                              font-size: 22px;
                              cursor: pointer;
                              color: #ff7c47;
                            " v-on:click="printData" v-b-tooltip.hover.top title="Print"></i>
                        </div>
                      </div>
                    </div>
                  </template>



                  <template v-for="field in reportTableFields" v-slot:[`head(${field.key})`]="{ label }">
                    <span class="" style="color: #ff7c47;" v-bind:key="field.key">{{
      label
    }}</span>
                  </template>

                  <template v-for="field in reportTableFields" v-slot:[`cell(${field.key})`]="data">
                    <span v-bind:key="field.key">{{ data.value }}</span>
                  </template>

                  <template #cell(action)="row">
                    <div class="text-center">
                      <b-button v-b-modal.modal-edit-role variant="outline-info" size="sm"
                        v-on:click="showScreenShotGallary(row.item)">
                        <i class="ri-screenshot-2-fill" style="font-size: 16px!important"></i>
                      </b-button>
                    </div>
                  </template>

                  <template #cell(user_name)="row">
                    <div class="text-center-">
                      <span style="cursor: pointer;" v-b-tooltip.hover.top :title="userDateTasksfunction(row.item)"
                        v-on:click="redirectToAvtivitie(row.item)">
                        {{ row.item.user_name }}
                      </span>
                    </div>
                  </template>

                  <!-- <template #cell(meeting_time)="row">
                    <div class="text-center-">
                      <span style="cursor: pointer;" v-b-tooltip.hover.top :title="userDateTasksfunction(row.item)"
                        v-on:click="redirectToAvtivitie(row.item)">
                        {{ userDateTasksfunction(row.item) }}
                      </span>
                    </div>
                  </template> -->







                  <template #cell(url_data)="row">
                    <div v-if="row.item.expand">
                      <ul>
                        <li v-for="(item, index) in row.item.url_data" :key="index">
                          <a target="_blank" :href="item.url"> {{ item.siteName }} </a>
                        </li>
                      </ul>
                    </div>
                    <div v-if="!row.item.expand && row.item.url_data.length > 0">
                      <b-button variant="link" v-on:click="expandUrls(row.item)">Visited {{ row.item.url_data.length }}
                        URL
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>
                </b-table>

                <div class="">
                  <!-- <b-modal id="modal-edit-role" centered title="Member'screen shots" hide-footer>

                  </b-modal> -->

                  <b-modal id="modal-edit-role" size="xl" title="Member's screen shots" hide-footer>
                    <div class="mb-4">
                      <div class=" card h-100-"
                        style="margin-top: auto;margin-bottom: auto;height: 65vh;overflow: auto;">
                        <div class="card-body ">
                          <!-- <h4 class="card-title mb-4">Recent Activity</h4> -->
                          <div v-if="true">
                            <div class="row thumbnailimages">
                              <!-- <div class="col-2 mb-4 card-image-div" v-for="(data, index) in recentDataScreen"
                                :key="index">
                                <div style="cursor: pointer; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;"
                                  v-on:click="invokeModal('img-view', index)" class="card hovers">
                                  <b-img-lazy class="card-img" :show="true" :src="data.thumb" height="90px"
                                    width="100%">
                                  </b-img-lazy>
                                </div>
                              </div> -->
                            </div>
                          </div>
                          <div class="row" v-if="isImageLoading">
                            <div class="col-2 mb-4" v-for="item in recentDataLenth" :key="item">
                              <div style="cursor: pointer; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;" class="card ">
                                <b-img-lazy class="card-img shimmer border-0" :show="true" height="90px" width="100%">
                                </b-img-lazy>

                              </div>
                            </div>
                          </div>
                          <!-- <div v-if="isImageLoading" class="d-flex align-items-center justify-content-center h-100">
                            <b-spinner class="" label="Large Spinner" variant="primary"></b-spinner>
                          </div> -->
                        </div>

                      </div>
                      <!-- <div class="" v-if="true">
                        <div class="">
                          <div class="card h-100">
                            <div class="card-body">
                              <div class="text-center mt-4 mb-4">
                                <b-spinner class="align-middle"></b-spinner>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </b-modal>
                  <b-modal id="img-view" size="xl" title-class="font-18" class="mb-4" hide-footer>
                    <img :src="selectedFile" width="100%" style="height: 70vh;" />
                  </b-modal>

                </div>
              </div>
            </div>
            <div class="report-bottom text-right" v-if="reportTableData.length > 0"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
@media print {
  .card {
    font-size: 1rem;
  }



  .report-form-label,
  .actions {
    display: none;
    padding-top: 15px;
    margin-bottom: -10px;
  }

  .report-form-row>div {
    display: none;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .report-form-row .form-control,
  .report-form-row .form-control label,
  .report-form-row .custom-select {
    display: none !important;
  }

  .b-form-btn-label-control.form-control>#from-datepicker,
  .b-form-btn-label-control.form-control>#to-datepicker,
  .report-search,
  .report-bottom {
    display: none !important;
  }
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

#from-datepicker>svg {
  display: none;
}

#to-datepicker>svg {
  display: none;
}

/* #reportTable tr:last-child td {
  color: black;
  font-weight: bolder;
} */
</style>

<style>
.dropdown-width .dropdown-content {
  min-width: 108px;
}

.text-red {
  color: red !important;
}

.shimmer {
  /* Hide the actual image initially */

  /* transition: opacity 1s ease-in-out; */
  /* background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%); */
  animation: shimmer 5s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 5%, #ffffff 25%, #e6e6e6 35%);
  background-size: 1000px 100%;
}





@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }

  to {
    background-position: 1000px 0;
  }
}

.hovers:hover .card-img {
  animation: hover-scale 0.1s ease-in-out forwards;
}

@keyframes hover-scale {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
}

.card-body-height {
  height: 80vh !important;
  background-blend-mode: darken;
}
</style>
